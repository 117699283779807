@media (orientation: landscape) {
    .word {
        font-size: 2vw; /* Size for landscape orientation */
    }
}

@media (orientation: portrait) {
    .word {
        font-size: 3vw; /* Size for portrait orientation */
    }
}