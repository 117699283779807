.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.name, .description, .progress {
  font-weight: bolder;
}

.carousel > .container {
  position: relative; /* This becomes the reference for .visit-link */
  flex: 1;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--main-color);
}


.visit-link {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 10px; /* Adds 4px padding */
  border-bottom: 2px solid black; /* Adds black border at the bottom */
  border-left: 2px solid black; /* Adds black border on the left */
  text-decoration: none;
  font-weight: bold;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}




.carousel-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}

.carousel-controls a {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main-color);
  padding: 1vw;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.carousel-controls a:hover {
  background-color: var(--main-color);
  color: #000;
}

.inverted .container,
.inverted .slide,
.inverted .container > .slide > * {
  background-color: var(--main-color);
  color: #000;
}

@media (orientation: portrait) {
  .carousel > .container {
    padding: 2vh;
    min-height: 30vh;
    min-width: 25vh;
    margin: 0 4vw;
  }

  .carousel-controls {
    width: 10vh;
    height: 10vh;
    font-size: 6vh;
  }
  
  .name {
    font-size: 4vh;
  }

  .description, .progress {
    font-size: 2vh;
  }

  .visit-link {
    color: var(--main-color) !important;
  }
}

@media (orientation: landscape) {
  .carousel > .container {
    padding: 2vw;
    min-height: 20vw;
    min-width: 0vw;
    margin: 0 2vw;
  }

  .carousel-controls {
    width: 5vw; /* Adjust control width for landscape */
    height: 5vw; /* Adjust control height for landscape */
    font-size: 2vw;
  }

  .name {
    font-size: 3vw;
  }

  .description, .progress {
    font-size: 2vw;
  }

  .visit-link {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .visit-link:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }  
}