/* src/styles/Button.css */

.portrait-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10% !important; /* Adjusted height for better visibility */
    font-size: 10vw !important;
    font-weight: bold !important;
    cursor: pointer;
    user-select: none;
    position: relative;
  }

  .portrait-nav.top::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      to right,
      var(--main-color) 50%,
      transparent 50%
    );
    background-size: 8vw 10px; /* Dash width and space */
  }
  
  .portrait-nav.bottom::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      to right,
      var(--main-color) 50%,
      transparent 50%
    );
    background-size: 8vw 10px; /* Dash width and space */
}
  
  