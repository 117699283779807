.typing-text {
  visibility: hidden; /* Hide all child elements initially */
  position: relative;
  /* Add any other existing styles here */
}


.cursor {
  width: .5em;
  background-color: #000000; /* none */
  animation: blink 1s step-end infinite;
  margin-left: 2px;
  height: 1em;
}

@keyframes blink {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
