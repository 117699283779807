/* src/styles/Navbar.css */

.navbar {
  width: 33%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  position: relative;
  font-family: 'Courier New', Courier, monospace;
  outline: none; /* Remove the default focus outline */
}  
  
  .navbar::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(
      to bottom,
      var(--main-color) 50%,
      transparent 50%
    );
    background-size: 2px 20px;
    /*background-color: var(--main-color);*/
    /*transition: inherit;*/
  }

  /*
  <input type="range" min="0" max={colors.length - 1} value={colorIndex} onChange={onColorChange} className="color-slider"/>
  color-slider {
    margin-bottom: 20px;
    appearance: none;
    outline: none;
    border: 1px solid var(--main-color);
    width: 16vw;
    height: 2vw;
  }

  .color-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 3vw;
    height: 3vw;
    background: var(--main-color);
  }
  */
  
  .title {
    font-size: 4vw;
    font-weight: bold;
  }
  
  .navbar-container {
    align-self: center;
  }
  
  .navbar-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 2vw;
    margin-bottom: 10px;
    padding-left: 3vw;
    font-weight: bold;
  }
    
  .arrow {
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    white-space: nowrap;
  }
  