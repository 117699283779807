/* src/styles/App.css */

body, html {
  margin: 0;
  padding: 0;
}

.landscape {
  display: flex;
  flex-direction: row; /* Ensure items are arranged in a row */
  width: 100vw;
  height: 100vh; /* Ensure full viewport height */
}

.portrait {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landscape-section {
  width: 67vw;
  padding: 20px;
}

.portrait-section {
  height: 80vh; /* Adjusted height for portrait section */
  padding: 20px;
}

@media (orientation: landscape) {
  .landscape-section {
    font-size: 2vw;
  }
}
  
@media (orientation: portrait) {
  .portrait-section {
    font-size: 3vh;
  }
}
