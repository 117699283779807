.about {
}

.container {
    width: 100%;
    text-align: left;
}

.UK {
    font-weight: bold;
    color: #1c379e;
}

.year {
    font-weight: bolder;
}

.concept1 {
    position: relative;
    display: inline-block;
    font-weight: bolder;
    color: rgb(255, 85, 0);
    animation: glitch1 1s infinite, movement1 2s infinite;
}

.concept2 {
    position: relative;
    display: inline-block;
    font-weight: bolder;
    color: rgb(255, 85, 0);
    animation: glitch2 1s infinite, movement2 2s infinite;
}

.resume-link {
    font-weight: bold;
    color: rgb(255, 255, 255);
    animation: resumeColor 4s infinite;
    transition: color 2s;
    text-decoration: none;
}

@media (orientation: landscape) {
    .about {
        margin-top: 4vw;
    }
}

@media (orientation: portrait) {
    .about {
        margin-top: 0vh;
    }
}

@keyframes resumeColor {
    0%, 100% {
        color: #ffccff;
    }
    25% {
        color: #ffffcc;
    }
    50% {
        color: #ccffff;
    }
    75% {
        color: #ccffcc;
    }
}

@keyframes glitch1 {
    0% {
      clip-path: polygon(
        0% 43%,
        83% 43%,
        83% 22%,
        23% 22%,
        23% 24%,
        91% 24%,
        91% 26%,
        18% 26%,
        18% 83%,
        29% 83%,
        29% 17%,
        41% 17%,
        41% 39%,
        18% 39%,
        18% 82%,
        54% 82%,
        54% 88%,
        19% 88%,
        19% 4%,
        39% 4%,
        39% 14%,
        76% 14%,
        76% 52%,
        23% 52%,
        23% 35%,
        19% 35%,
        19% 8%,
        36% 8%,
        36% 31%,
        73% 31%,
        73% 16%,
        1% 16%,
        1% 56%,
        50% 56%,
        50% 8%
      );
    }
  
    5% {
      clip-path: polygon(
        0% 29%,
        44% 29%,
        44% 83%,
        94% 83%,
        94% 56%,
        11% 56%,
        11% 64%,
        94% 64%,
        94% 70%,
        88% 70%,
        88% 32%,
        18% 32%,
        18% 96%,
        10% 96%,
        10% 62%,
        9% 62%,
        9% 84%,
        68% 84%,
        68% 50%,
        52% 50%,
        52% 55%,
        35% 55%,
        35% 87%,
        25% 87%,
        25% 39%,
        15% 39%,
        15% 88%,
        52% 88%
      );
    }
  
    30% {
      clip-path: polygon(
        0% 53%,
        93% 53%,
        93% 62%,
        68% 62%,
        68% 37%,
        97% 37%,
        97% 89%,
        13% 89%,
        13% 45%,
        51% 45%,
        51% 88%,
        17% 88%,
        17% 54%,
        81% 54%,
        81% 75%,
        79% 75%,
        79% 76%,
        38% 76%,
        38% 28%,
        61% 28%,
        61% 12%,
        55% 12%,
        55% 62%,
        68% 62%,
        68% 51%,
        0% 51%,
        0% 92%,
        63% 92%,
        63% 4%,
        65% 4%
      );
    }
  
    45% {
      clip-path: polygon(
        0% 33%,
        2% 33%,
        2% 69%,
        58% 69%,
        58% 94%,
        55% 94%,
        55% 25%,
        33% 25%,
        33% 85%,
        16% 85%,
        16% 19%,
        5% 19%,
        5% 20%,
        79% 20%,
        79% 96%,
        93% 96%,
        93% 50%,
        5% 50%,
        5% 74%,
        55% 74%,
        55% 57%,
        96% 57%,
        96% 59%,
        87% 59%,
        87% 65%,
        82% 65%,
        82% 39%,
        63% 39%,
        63% 92%,
        4% 92%,
        4% 36%,
        24% 36%,
        24% 70%,
        1% 70%,
        1% 43%,
        15% 43%,
        15% 28%,
        23% 28%,
        23% 71%,
        90% 71%,
        90% 86%,
        97% 86%,
        97% 1%,
        60% 1%,
        60% 67%,
        71% 67%,
        71% 91%,
        17% 91%,
        17% 14%,
        39% 14%,
        39% 30%,
        58% 30%,
        58% 11%,
        52% 11%,
        52% 83%,
        68% 83%
      );
    }
  
    76% {
      clip-path: polygon(
        0% 26%,
        15% 26%,
        15% 73%,
        72% 73%,
        72% 70%,
        77% 70%,
        77% 75%,
        8% 75%,
        8% 42%,
        4% 42%,
        4% 61%,
        17% 61%,
        17% 12%,
        26% 12%,
        26% 63%,
        73% 63%,
        73% 43%,
        90% 43%,
        90% 67%,
        50% 67%,
        50% 41%,
        42% 41%,
        42% 46%,
        50% 46%,
        50% 84%,
        96% 84%,
        96% 78%,
        49% 78%,
        49% 25%,
        63% 25%,
        63% 14%
      );
    }
  
    90% {
      clip-path: polygon(
        0% 41%,
        13% 41%,
        13% 6%,
        87% 6%,
        87% 93%,
        10% 93%,
        10% 13%,
        89% 13%,
        89% 6%,
        3% 6%,
        3% 8%,
        16% 8%,
        16% 79%,
        0% 79%,
        0% 99%,
        92% 99%,
        92% 90%,
        5% 90%,
        5% 60%,
        0% 60%,
        0% 48%,
        89% 48%,
        89% 13%,
        80% 13%,
        80% 43%,
        95% 43%,
        95% 19%,
        80% 19%,
        80% 85%,
        38% 85%,
        38% 62%
      );
    }
  
    1%,
    7%,
    33%,
    47%,
    78%,
    93% {
      clip-path: none;
    }
  }

@keyframes glitch2 {
    0% {
        clip-path: polygon(
          81% 55%,
          19% 55%,
          19% 63%,
          5% 63%,
          5% 43%,
          90% 43%,
          14% 92%,
          14% 23%,
          77% 23%,
          77% 9%,
          8% 9%,
          8% 15%,
          38% 15%,
          38% 79%,
          12% 79%,
          12% 95%,
          90% 21%,
          71% 21%,
          71% 68%,
          94% 68%,
          94% 30%,
          0% 41%,
          26% 41%,
          26% 7%,
          83% 7%,
          83% 92%,
          81% 95%,
          56% 30%,
          56% 84%,
          25% 84%,
          25% 49%
        );
      }
    
    5% {
        clip-path: polygon(
            57% 45%,
            83% 45%,
            83% 14%,
            9% 14%,
            71% 47%,
            94% 47%,
            94% 70%,
            21% 70%,
            21% 37%,
            0% 63%,
            82% 63%,
            82% 21%,
            71% 21%,
            29% 59%,
            91% 59%,
            95% 62%,
            95% 28%,
            4% 28%,
            91% 18%,
            13% 18%,
            13% 77%,
            57% 77%,
            63% 37%,
            63% 86%,
            29% 86%,
            9% 62%,
            4% 90%,
            77% 90%,
            77% 7%,
            88% 7%
        );
    }

    30% {
      clip-path: polygon(
        6% 86%,
        6% 40%,
        4% 40%,
        4% 72%,
        85% 72%,
        85% 60%,
        0% 58%,
        39% 58%,
        39% 76%,
        81% 76%,
        81% 33%,
        34% 60%,
        34% 19%,
        22% 19%,
        22% 93%,
        10% 93%,
        10% 27%,
        49% 27%,
        49% 88%,
        15% 33%,
        15% 47%,
        77% 47%,
        77% 54%,
        91% 54%,
        91% 23%,
        28% 23%,
        28% 86%,
        72% 88%
      );
    }

    40% {
        clip-path: polygon(
          12% 56%,
          74% 56%,
          74% 11%,
          78% 25%,
          12% 33%,
          52% 89%,
          15% 89%,
          15% 39%,
          66% 39%,
          66% 10%,
          37% 11%,
          37% 93%,
          19% 93%,
          19% 25%,
          29% 10%,
          29% 82%,
          78% 47%,
          52% 47%,
          0% 71%,
          63% 71%,
          63% 14%,
          45% 14%,
          45% 77%,
          89% 77%,
          89% 45%,
          23% 45%,
          23% 68%,
          56% 68%,
          56% 33%,
          47% 82%,
          47% 53%,
          5% 53%,
          5% 91%,
          83% 91%,
          83% 4%
        );
      }

    76% {
      clip-path: polygon(
        69% 81%,
        81% 81%,
        81% 48%,
        18% 48%,
        18% 76%,
        9% 76%,
        82% 31%,
        93% 31%,
        93% 58%,
        0% 36%,
        27% 36%,
        27% 60%,
        69% 60%,
        39% 58%,
        39% 40%,
        51% 40%,
        51% 72%,
        8% 72%,
        8% 44%,
        60% 44%,
        9% 29%,
        32% 29%,
        32% 67%,
        44% 67%,
        44% 53%,
        82% 53%,
        60% 88%,
        95% 88%,
        95% 14%,
        21% 14%,
        21% 9%
      );
    }
    
    90% {
        clip-path: polygon(
          97% 37%,
          91% 68%,
          44% 68%,
          44% 32%,
          77% 32%,
          77% 75%,
          0% 23%,
          15% 23%,
          15% 84%,
          69% 84%,
          69% 95%,
          48% 95%,
          48% 35%,
          6% 69%,
          52% 69%,
          52% 22%,
          97% 22%,
          3% 75%,
          3% 11%,
          11% 35%,
          11% 77%,
          38% 77%,
          38% 13%,
          5% 13%,
          5% 67%,
          76% 67%,
          76% 44%,
          93% 44%,
          93% 51%,
          6% 51%,
          64% 11%,
          64% 53%,
          18% 53%,
          18% 12%,
          84% 37%,
          84% 50%,
          66% 50%,
          66% 91%,
          12% 91%,
          12% 24%,
          4% 24%,
          4% 62%,
          71% 62%,
          71% 49%,
          1% 49%,
          1% 39%,
          29% 39%,
          29% 88%,
          55% 88%,
          55% 7%,
          91% 7%,
          85% 12%,
          85% 90%
        );
      }

    1%,
    7%,
    33%,
    47%,
    78%,
    93% {
      clip-path: none;
    }
  }
