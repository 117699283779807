.contact {}
  
.container {
    width: 100%;
    text-align: left;
}

.bug {
    font-weight: bold;
}

.call {
    font-weight: bold;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.email {
    font-weight: bold;
    text-decoration: none;
}


@media (orientation: landscape) {
    .contact {
        margin-top: 6vw;
    }
}
    
@media (orientation: portrait) {
    .contact {
        margin-top: 0vh;
    }
}